import React, { useState, useEffect } from 'react';
import {
    Container,
    Typography,
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    CircularProgress,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

const TeamPage = () => {
    const [translators, setTranslators] = useState([]);
    const [loading, setLoading] = useState(true); // Loading state
    const [error, setError] = useState(''); // Error state
    const [openInviteDialog, setOpenInviteDialog] = useState(false);
    const [inviteEmail, setInviteEmail] = useState('');
    const [selectedTranslator, setSelectedTranslator] = useState(null); // For editing translator's projects
    const token = useSelector((state) => state.auth.token); // Access JWT token from Redux
    const navigate = useNavigate();

    // Fetch translators when the component mounts
    useEffect(() => {
        const fetchTranslators = async () => {
            try {
                const response = await axios.get('http://192.168.68.130:5002/team', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                console.log('Translators fetched:', response.data);
                setTranslators(response.data); // Ensure that the data from the API is being saved
                setLoading(false);
            } catch (err) {
                console.error('Error fetching translators:', err);
                setError('Failed to load translators');
                setLoading(false);
            }
        };

        fetchTranslators();
    }, [token]);

    // Handle inviting a new translator
    const handleInviteTranslator = async () => {
        try {
            await axios.post(
                'http://192.168.68.130:5002/team/invite',
                { email: inviteEmail },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            alert('Invitation sent successfully');
            setOpenInviteDialog(false);
            setInviteEmail('');
            // Add invited translator to the list with pending status
            setTranslators([...translators, { email: inviteEmail, status: 'pending' }]);
        } catch (err) {
            console.error('Error sending invitation:', err);
            alert('Failed to send invitation');
        }
    };

    // Handle editing translator's projects
    const handleEditProjects = (translator) => {
        setSelectedTranslator(translator);
        navigate(`/dashboard/team/${translator._id}/edit-projects`);
    };

    const handleRemoveTranslator = async (translatorId) => {
        if (!window.confirm('Are you sure you want to remove this translator from the team?')) return;

        try {
            await axios.delete(`http://192.168.68.130:5002/team/translators/${translatorId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setTranslators(translators.filter((translator) => translator.userId._id !== translatorId));
            alert('Translator removed successfully');
        } catch (err) {
            console.error('Error removing translator:', err);
            alert('Failed to remove translator');
        }
    };


    if (loading) {
        return (
            <Container sx={{ marginTop: '50px', textAlign: 'center' }}>
                <CircularProgress />
            </Container>
        );
    }

    return (
        <Container maxWidth="lg" sx={{ marginTop: '50px' }}>
            <Typography variant="h4" gutterBottom>
                Team Management
            </Typography>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                <Typography variant="h6">Translators</Typography>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<PersonAddIcon />}
                    onClick={() => setOpenInviteDialog(true)}
                >
                    Invite Translator
                </Button>
            </Box>

            {/* Always display the table even if no translators */}
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Assigned Projects</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {translators.length === 0 ? (
                            <TableRow>
                                <TableCell colSpan={5} align="center">
                                    No translators have been added to the team yet.
                                </TableCell>
                            </TableRow>
                        ) : (
                            translators.map((translator) => (
                                <TableRow key={translator._id}>
                                    <TableCell>
                                        {translator.userId?.firstName && translator.userId?.lastName
                                            ? `${translator.userId.firstName} ${translator.userId.lastName}`
                                            : 'N/A'}
                                    </TableCell>
                                    <TableCell>{translator.userId?.email || 'N/A'}</TableCell>
                                    <TableCell>{translator.status === 'pending' ? 'Pending' : 'Active'}</TableCell>
                                    <TableCell>{translator.assignedProjectsCount || 0}</TableCell> {/* Display project count */}
                                    <TableCell>
                                        {translator.status !== 'pending' && (
                                            <>
                                                <IconButton
                                                    color="primary"
                                                    onClick={() => handleEditProjects(translator)}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton
                                                    color="secondary"
                                                    onClick={() => handleRemoveTranslator(translator.userId._id)}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </>
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>

                </Table>
            </TableContainer>

            {/* Invite Translator Dialog */}
            <Dialog open={openInviteDialog} onClose={() => setOpenInviteDialog(false)}>
                <DialogTitle>Invite a New Translator</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Translator's Email"
                        type="email"
                        fullWidth
                        value={inviteEmail}
                        onChange={(e) => setInviteEmail(e.target.value)}
                        sx={{ marginBottom: '20px' }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenInviteDialog(false)}>Cancel</Button>
                    <Button onClick={handleInviteTranslator} variant="contained" color="primary">
                        Send Invitation
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default TeamPage;
