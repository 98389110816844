import React, { useState, useEffect } from 'react';
import { Container, TextField, Button, Typography, Paper, Box, CircularProgress } from '@mui/material';
import axios from 'axios';
import { useSelector } from 'react-redux';

const AccountSettings = () => {
    const token = useSelector((state) => state.auth.token);
    const [deepLApiKey, setDeepLApiKey] = useState('');
    const [message, setMessage] = useState('');
    const [usageData, setUsageData] = useState(null);
    const [loading, setLoading] = useState(true);

    // Fetch DeepL API key and usage data
    useEffect(() => {
        const fetchApiKeyAndUsage = async () => {
            try {
                // Fetch user's DeepL API key
                const apiKeyResponse = await axios.get('http://192.168.68.130:5002/users/me', {
                    headers: { Authorization: `Bearer ${token}` },
                });
                const apiKey = apiKeyResponse.data.deepLApiKey || '';
                setDeepLApiKey(apiKey);

                // Fetch DeepL usage data only if API key is available
                if (apiKey) {
                    const usageResponse = await axios.get('http://192.168.68.130:5002/deepl/usage', {
                        headers: { Authorization: `Bearer ${token}` },
                    });
                    setUsageData(usageResponse.data);
                }
            } catch (err) {
                console.error('Error fetching data:', err);
            } finally {
                setLoading(false);
            }
        };
        fetchApiKeyAndUsage();
    }, [token]);

    const handleSave = async () => {
        try {
            await axios.put(
                'http://192.168.68.130:5002/users/me/ApiKey',
                { deepLApiKey },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            setMessage('API Key saved successfully.');
        } catch (err) {
            console.error('Error saving API key:', err);
            setMessage('Error saving API key.');
        }
    };

    return (
        <Container maxWidth="sm" sx={{ marginTop: '50px' }}>
            <Paper elevation={3} sx={{ padding: '30px', borderRadius: '10px' }}>
                <Typography variant="h4" gutterBottom>Account Settings</Typography>
                <Box sx={{ marginBottom: '20px' }}>
                    <TextField
                        label="DeepL API Key"
                        variant="outlined"
                        fullWidth
                        value={deepLApiKey}
                        onChange={(e) => setDeepLApiKey(e.target.value)}
                    />
                </Box>
                <Button variant="contained" color="primary" onClick={handleSave}>
                    Save API Key
                </Button>
                {message && <Typography color="success">{message}</Typography>}

                {/* Display usage information */}
                {loading ? (
                    <CircularProgress />
                ) : usageData ? (
                    <Box sx={{ marginTop: '20px' }}>
                        <Typography variant="h6">Current Usage</Typography>
                        <Typography>Character Count: {usageData.character_count}</Typography>
                        <Typography>Character Limit: {usageData.character_limit}</Typography>
                        <Typography>
                            Percentage Used: {((usageData.character_count / usageData.character_limit) * 100).toFixed(2)}%
                        </Typography>
                    </Box>
                ) : (
                    <Typography sx={{ marginTop: '20px' }}>
                        No usage data available.
                    </Typography>
                )}
            </Paper>
        </Container>
    );
};

export default AccountSettings;
