import React, { useState, useEffect } from 'react';
import { Container, Typography, Paper, Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Checkbox, LinearProgress, Tabs, Tab, IconButton, TableSortLabel } from '@mui/material';
import Flag from 'react-world-flags'; // For displaying flags
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import TranslateIcon from '@mui/icons-material/GTranslate';

const TranslationProjects = () => {
    const [createdProjects, setCreatedProjects] = useState([]); // For projects created by the user
    const [sharedProjects, setSharedProjects] = useState([]);   // For projects shared with the user
    const [selectedProjects, setSelectedProjects] = useState([]); // Manage selected projects
    const [sortConfig, setSortConfig] = useState({ key: 'projectName', direction: 'asc' }); // Sorting state
    const token = useSelector((state) => state.auth.token);
    const navigate = useNavigate();

    // Fetch projects when the component mounts
    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const response = await axios.get('http://192.168.68.130:5002/projects', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                const created = [];
                const shared = [];

                // Separate created and shared projects
                response.data.forEach((project) => {
                    const totalSections = project.sections.length;
                    const approvedSections = project.approvedSections ? project.approvedSections.length : 0;
                    const progress = totalSections > 0 ? (approvedSections / totalSections) * 100 : 0;

                    const updatedProject = {
                        ...project,
                        progress: progress.toFixed(2),
                    };

                    if (project.createdBy) {
                        shared.push(updatedProject); // If project has a createdBy field, it's shared
                    } else {
                        created.push(updatedProject); // Otherwise, it's created by the user
                    }
                });

                setCreatedProjects(created);
                setSharedProjects(shared);
            } catch (err) {
                console.error('Error fetching projects:', err);
            }
        };

        fetchProjects();
    }, [token]);

    // Handle project deletion
    const handleDeleteProject = async (projectId) => {
        if (!window.confirm('Are you sure you want to delete this project?')) return;

        try {
            await axios.delete(`http://192.168.68.130:5002/projects/${projectId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            setCreatedProjects(createdProjects.filter((project) => project._id !== projectId));
        } catch (err) {
            console.error('Error deleting project:', err);
        }
    };

    // Handle selection change
    const handleSelectProject = (projectId) => {
        setSelectedProjects((prevSelected) => {
            if (prevSelected.includes(projectId)) {
                return prevSelected.filter((id) => id !== projectId);
            } else {
                return [...prevSelected, projectId];
            }
        });
    };

    // Handle sorting
    const handleSort = (column) => {
        setSortConfig((prevSortConfig) => {
            const isAsc = prevSortConfig.key === column && prevSortConfig.direction === 'asc';
            return { key: column, direction: isAsc ? 'desc' : 'asc' };
        });
    };

    // Sort projects based on the current sorting configuration
    const sortProjects = (projects) => {
        return [...projects].sort((a, b) => {
            if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'asc' ? -1 : 1;
            if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'asc' ? 1 : -1;
            return 0;
        });
    };

    return (
        <Container maxWidth="lg" sx={{ marginTop: '50px' }}>
            <Typography variant="h4" gutterBottom>Translation Projects</Typography>

            {/* Created Projects Table */}
            <Typography variant="h5" sx={{ marginTop: '20px' }}>Projects You Created</Typography>
            {createdProjects.length > 0 ? (
                <>
                    {/* Action buttons for selected projects */}
                    {selectedProjects.length > 0 && (
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
                            <IconButton onClick={() => navigate(`/dashboard/projects/${selectedProjects[0]}/edit`)}>
                                <EditIcon />
                            </IconButton>
                            <IconButton onClick={() => handleDeleteProject(selectedProjects[0])}>
                                <DeleteIcon />
                            </IconButton>
                            <IconButton>
                                <CloudDownloadIcon />
                            </IconButton>
                        </Box>
                    )}

                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            indeterminate={selectedProjects.length > 0 && selectedProjects.length < createdProjects.length}
                                            checked={selectedProjects.length === createdProjects.length}
                                            onChange={(e) => setSelectedProjects(e.target.checked ? createdProjects.map((p) => p._id) : [])}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={sortConfig.key === 'projectName'}
                                            direction={sortConfig.direction}
                                            onClick={() => handleSort('projectName')}
                                        >
                                            Project Name
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={sortConfig.key === 'sourceLanguage'}
                                            direction={sortConfig.direction}
                                            onClick={() => handleSort('sourceLanguage')}
                                        >
                                            Source
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={sortConfig.key === 'targetLanguage'}
                                            direction={sortConfig.direction}
                                            onClick={() => handleSort('targetLanguage')}
                                        >
                                            Target
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>Progress</TableCell>
                                    <TableCell>Deadline</TableCell>
                                    <TableCell>Translate</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sortProjects(createdProjects).map((project) => (
                                    <TableRow key={project._id}>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={selectedProjects.includes(project._id)}
                                                onChange={() => handleSelectProject(project._id)}
                                            />
                                        </TableCell>
                                        <TableCell>{project.projectName}</TableCell>
                                        <TableCell>
                                            <Flag code={project.sourceLanguage} style={{ marginRight: '10px', width: '20px' }} />
                                            {project.sourceLanguage}
                                        </TableCell>
                                        <TableCell>
                                            <Flag code={project.targetLanguage} style={{ marginRight: '10px', width: '20px' }} />
                                            {project.targetLanguage}
                                        </TableCell>
                                        <TableCell>
                                            <LinearProgress variant="determinate" value={project.progress} />
                                            <Typography variant="body2">{project.progress}%</Typography>
                                        </TableCell>
                                        <TableCell>{project.deadline ? new Date(project.deadline).toLocaleDateString() : 'N/A'}</TableCell>
                                        <TableCell>
                                            <IconButton onClick={() => navigate(`/dashboard/projects/${project._id}/translate`)} color="primary">
                                                <TranslateIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            ) : (
                <Box textAlign="center" sx={{ mt: 4 }}>
                    <Typography variant="h6">No projects found.</Typography>
                    <Button variant="contained" color="primary" onClick={() => navigate('/dashboard/projects/new-translation')}>
                        Create New Project
                    </Button>
                </Box>
            )}

            {/* Shared Projects Table */}
            <Typography variant="h5" sx={{ marginTop: '40px' }}>Shared Projects</Typography>
            {sharedProjects.length > 0 ? (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Project Name</TableCell>
                                <TableCell>Source</TableCell>
                                <TableCell>Target</TableCell>
                                <TableCell>Progress</TableCell>
                                <TableCell>Deadline</TableCell>
                                <TableCell>Created By</TableCell>
                                <TableCell>Translate</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortProjects(sharedProjects).map((project) => (
                                <TableRow key={project._id}>
                                    <TableCell>{project.projectName}</TableCell>
                                    <TableCell>
                                        <Flag code={project.sourceLanguage} style={{ marginRight: '10px', width: '20px' }} />
                                        {project.sourceLanguage}
                                    </TableCell>
                                    <TableCell>
                                        <Flag code={project.targetLanguage} style={{ marginRight: '10px', width: '20px' }} />
                                        {project.targetLanguage}
                                    </TableCell>
                                    <TableCell>
                                        <LinearProgress variant="determinate" value={project.progress} />
                                        <Typography variant="body2">{project.progress}%</Typography>
                                    </TableCell>
                                    <TableCell>{project.deadline ? new Date(project.deadline).toLocaleDateString() : 'N/A'}</TableCell>
                                    <TableCell>
                                        <Typography variant="body2" color="textSecondary">
                                            {project.createdBy}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => navigate(`/dashboard/projects/${project._id}/translate`)} color="primary">
                                            <TranslateIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Box textAlign="center" sx={{ mt: 4 }}>
                    <Typography variant="h6">No shared projects found.</Typography>
                </Box>
            )}
        </Container>
    );
};

export default TranslationProjects;
