import React, { useEffect, useState } from 'react';
import {
    AppBar,
    Toolbar,
    Typography,
    Box,
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ThemeProvider,
    IconButton,
    Avatar,
    Menu,
    MenuItem,
    Divider
} from '@mui/material';
import { useNavigate, Link, Outlet } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import logout from '../slices/authSlice';
import AccountCircle from '@mui/icons-material/AccountCircle';
import TranslateIcon from '@mui/icons-material/Translate';
import FolderIcon from '@mui/icons-material/Folder';
import SettingsIcon from '@mui/icons-material/Settings';
import HomeIcon from '@mui/icons-material/Home';
import GroupIcon from '@mui/icons-material/Group'; // Import icon for Team section
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import theme from '../theme';
import axios from 'axios';

function TranslationDashboard() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const token = useSelector((state) => state.auth.token);
    const [profileData, setProfileData] = useState({});
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    // Fetch profile data (including avatar URL, first name, last name, etc.)
    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const response = await axios.get('http://192.168.68.130:5002/users/me', {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setProfileData(response.data);
            } catch (error) {
                console.error('Error fetching profile data:', error);
            }
        };

        fetchProfileData();
    }, [token]);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        console.log('Logging out...');
        // Dispatch the logout action to clear token and user from Redux
        dispatch(logout());
        // Navigate back to the homepage after logging out
        navigate('/');
    };

    // Sidebar menu options
    const menuItems = [
        { text: 'Dashboard', icon: <HomeIcon />, path: '' },
        { text: 'Translation Projects', icon: <FolderIcon />, path: 'projects' },
        { text: 'Start New Translation', icon: <TranslateIcon />, path: 'new-translation' },
        { text: 'Language Settings', icon: <SettingsIcon />, path: 'language-settings' },
        { text: 'Team', icon: <GroupIcon />, path: 'team' },  // Add Team section here
        { text: 'Account Settings', icon: <AccountCircle />, path: 'account-settings' },
    ];

    const toggleSidebar = () => {
        setIsSidebarCollapsed(!isSidebarCollapsed);
    };

    // Helper function to render user initials
    const getInitials = (name) => {
        const [firstName, lastName] = name.split(' ');
        return `${firstName?.[0] || ''}${lastName?.[0] || ''}`.toUpperCase();
    };

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex' }}>
                {/* App Bar */}
                <AppBar position="fixed" color="primary" sx={{ zIndex: theme.zIndex.drawer + 1 }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            onClick={toggleSidebar}
                            sx={{ mr: 2 }}
                        >
                            {isSidebarCollapsed ? <MenuIcon /> : <ChevronLeftIcon />}
                        </IconButton>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                            Translation Dashboard
                        </Typography>

                        {/* Avatar or User Initials */}
                        <IconButton
                            color="inherit"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenuClick}
                        >
                            {profileData.avatarUrl ? (
                                <Avatar
                                    src={`http://192.168.68.130:5002${profileData.avatarUrl}`}
                                    alt={profileData.firstName || 'User'}
                                />
                            ) : profileData.firstName || profileData.lastName ? (
                                <Avatar>
                                    {getInitials(`${profileData.firstName} ${profileData.lastName}`)}
                                </Avatar>
                            ) : (
                                <AccountCircle />
                            )}
                        </IconButton>

                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={open}
                            onClose={handleMenuClose}
                        >
                            <MenuItem
                                onClick={() => {
                                    navigate('/dashboard/profile');
                                    handleMenuClose();
                                }}
                            >
                                Profile
                            </MenuItem>
                            <MenuItem onClick={handleLogout}>Logout</MenuItem>
                        </Menu>
                    </Toolbar>
                </AppBar>

                {/* Sidebar Menu */}
                <Drawer
                    variant="permanent"
                    sx={{
                        width: isSidebarCollapsed ? 60 : 240,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: isSidebarCollapsed ? 60 : 240,
                            boxSizing: 'border-box',
                            backgroundColor: theme.palette.background.paper,
                            color: theme.palette.text.primary,
                        },
                    }}
                >
                    <Toolbar />
                    <List>
                        {menuItems.map((item) => (
                            <ListItem button key={item.text} component={Link} to={`/dashboard/${item.path}`}>
                                <ListItemIcon sx={{ color: theme.palette.text.primary }}>
                                    {item.icon}
                                </ListItemIcon>
                                {!isSidebarCollapsed && (
                                    <ListItemText primary={item.text} />
                                )}
                            </ListItem>
                        ))}
                    </List>
                    <Divider />
                </Drawer>

                {/* Nested Routes Outlet */}
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <Toolbar />
                    <Outlet /> {/* This is where nested routes will render their content */}
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default TranslationDashboard;
