import React, { useState, useEffect, useCallback } from 'react';
import { Container, Typography, Grid, TextField, Box, MenuItem, IconButton, LinearProgress, Button, Select, FormControl, InputLabel, Pagination, FormControlLabel, Checkbox } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Flag from 'react-world-flags';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UndoIcon from '@mui/icons-material/Undo';
import { jwtDecode } from "jwt-decode";



const TranslateProject = () => {
    const { id } = useParams();
    const [originalSections, setOriginalSections] = useState([]);
    const [translatedSections, setTranslatedSections] = useState([]);
    const [approvedSections, setApprovedSections] = useState([]);
    const [sourceLanguage, setSourceLanguage] = useState('');
    const [targetLanguage, setTargetLanguage] = useState('');
    const [overallProgress, setOverallProgress] = useState(0);
    const [pageProgress, setPageProgress] = useState(0);
    const [wordCount, setWordCount] = useState(0);
    const [charCount, setCharCount] = useState(0);
    const [translationService, setTranslationService] = useState('');
    const [teamMembers, setTeamMembers] = useState([]);
    const [excludeDates, setExcludeDates] = useState(false);
    const [excludeNumbers, setExcludeNumbers] = useState(false);
    const [excludeSymbols, setExcludeSymbols] = useState(false);
    const [projectCreator, setProjectCreator] = useState(''); // Store project creator
    const token = useSelector((state) => state.auth.token);

    let currentUserId;
    if (token) {
        const decodedToken = jwtDecode(token); // Decode the token
        currentUserId = decodedToken.id; // Extract the userId from the token payload (assuming it's under 'id')
    }

    const [currentPage, setCurrentPage] = useState(1);
    const sectionsPerPage = 50;
    const indexOfLastSection = currentPage * sectionsPerPage;
    const indexOfFirstSection = indexOfLastSection - sectionsPerPage;

    const isDate = (text) => /\b\d{1,2}[\/\-]\d{1,2}[\/\-]\d{2,4}\b/.test(text);
    const isNumber = (text) => /^[0-9]+$/.test(text);
    const isSymbol = (text) => /^[!@#\$%\^\&*\)\(+=._-]+$/.test(text);

    const filteredSections = originalSections.filter(section => {
        if (excludeDates && isDate(section)) return false;
        if (excludeNumbers && isNumber(section)) return false;
        if (excludeSymbols && isSymbol(section)) return false;
        return true;
    });

    const currentSections = filteredSections.slice(indexOfFirstSection, indexOfLastSection);

    const getCountryCodeForLanguage = (languageCode) => {
        const languageToCountryMap = {
            EN: 'GB',
            ES: 'ES',
            FR: 'FR',
            DE: 'DE',
            ZH: 'CN',
            JA: 'JP',
        };
        return languageToCountryMap[languageCode] || 'UN';
    };

    const calculateCounts = useCallback((sections) => {
        let totalWords = 0;
        let totalChars = 0;
        sections.forEach((section) => {
            const words = section.split(/\s+/).filter(Boolean).length;
            totalWords += words;
            totalChars += section.length;
        });
        setWordCount(totalWords);
        setCharCount(totalChars);
    }, []);

    const calculateOverallProgress = useCallback((approvedSections) => {
        const totalSections = originalSections.length;
        const approvedCount = approvedSections.length;
        const newProgress = totalSections > 0 ? (approvedCount / totalSections) * 100 : 0;
        setOverallProgress(newProgress);
    }, [originalSections.length]);

    const calculatePageProgress = useCallback((sections, approvedSections) => {
        const approvedOnPage = sections.filter((_, index) =>
            approvedSections.includes(index + indexOfFirstSection)
        ).length;
        const pageProgress = sections.length > 0 ? (approvedOnPage / sections.length) * 100 : 0;
        setPageProgress(pageProgress);
    }, [indexOfFirstSection]);

    useEffect(() => {
        const fetchTeamMembers = async () => {
            try {
                const response = await axios.get('http://192.168.68.130:5002/team', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setTeamMembers(response.data);
            } catch (err) {
                console.error('Error fetching team members:', err);
            }
        };
        fetchTeamMembers();
    }, [token]);

    useEffect(() => {
        const fetchProject = async () => {
            try {
                const response = await axios.get(`http://192.168.68.130:5002/projects/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                const project = response.data;
                console.log("Project creator ID:", project.createdBy);  // Debug: Log the creator's ID
                console.log("Current user ID:", currentUserId);  // Debug: Log the current user's ID
                setOriginalSections(project.sections);
                setTranslatedSections(project.translatedSections || []);
                setApprovedSections(project.approvedSections || []);
                setSourceLanguage(project.sourceLanguage);
                setTargetLanguage(project.targetLanguage);
                setProjectCreator(project.createdBy); // Set the project creator
                calculateCounts(project.sections);
                calculateOverallProgress(project.approvedSections || []);
                calculatePageProgress(project.sections.slice(indexOfFirstSection, indexOfLastSection), project.approvedSections || []);
            } catch (err) {
                console.error('Error fetching project:', err);
            }
        };

        fetchProject();
    }, [
        id,
        token,
        currentPage,
        indexOfFirstSection,
        indexOfLastSection,
        calculateCounts,
        calculateOverallProgress,
        calculatePageProgress,
    ]);

    const handleTranslationChange = (index, value) => {
        const updatedTranslations = [...translatedSections];
        updatedTranslations[index + indexOfFirstSection] = value;
        setTranslatedSections(updatedTranslations);
    };

    const toggleApproval = async (index) => {
        const globalIndex = index + indexOfFirstSection;
        const isApproved = approvedSections.includes(globalIndex);
        const updatedApproved = isApproved
            ? approvedSections.filter((i) => i !== globalIndex)
            : [...approvedSections, globalIndex];

        setApprovedSections(updatedApproved);
        calculateOverallProgress(updatedApproved);
        calculatePageProgress(currentSections, updatedApproved);

        try {
            await axios.put(`http://192.168.68.130:5002/projects/${id}/toggle-approval`, {
                sectionIndex: globalIndex,
                approved: !isApproved,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
        } catch (err) {
            console.error('Error saving toggle state:', err);
        }
    };

    const handleSaveTranslation = async () => {
        try {
            await axios.put(`http://192.168.68.130:5002/projects/${id}/translate`, {
                translatedSections,
                approvedSections,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            alert('Translation saved successfully!');
        } catch (err) {
            console.error('Error saving translation:', err);
            alert('Error saving translation.');
        }
    };

    const handleTranslate = async (index) => {
        const originalText = originalSections[index + indexOfFirstSection];

        if (!translationService) {
            alert('Please select a translation service.');
            return;
        }

        if (!translatedSections[index + indexOfFirstSection]) {
            try {
                const response = await axios.post(`http://192.168.68.130:5002/translate`, {
                    text: originalText,
                    sourceLanguage,
                    targetLanguage,
                    service: translationService,
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                const translatedText = response.data.translatedText;
                handleTranslationChange(index, translatedText);
            } catch (err) {
                console.error('Error translating text:', err);
                alert('Error translating text.');
            }
        }
    };

    const handleAssignTranslator = async (translatorId) => {
        try {
            if (!translatorId) {
                alert('Please select a translator.');
                return;
            }

            await axios.put(`http://192.168.68.130:5002/projects/${id}/assign-translator`, {
                translatorId,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            alert('Translator updated successfully!');
        } catch (err) {
            console.error('Error assigning translator:', err);
            alert('Error assigning translator.');
        }
    };

    const handleTranslatorChange = (e) => {
        const selectedTranslatorId = e.target.value;
        handleAssignTranslator(selectedTranslatorId);
    };

    const handleAutoTranslate = async () => {
        if (!translationService) {
            alert('Please select a translation service.');
            return;
        }

        for (let index = 0; index < currentSections.length; index++) {
            await handleTranslate(index);
        }
    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    return (
        <Container maxWidth="lg" sx={{ marginTop: '50px' }}>
            {/* Source and Target Language Context */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Flag code={getCountryCodeForLanguage(sourceLanguage)} style={{ width: '40px', marginRight: '10px' }} />
                    <Typography variant="h6">Source Language: {sourceLanguage}</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h6">Target Language: {targetLanguage}</Typography>
                    <Flag code={getCountryCodeForLanguage(targetLanguage)} style={{ width: '40px', marginLeft: '10px' }} />
                </Box>
            </Box>

            {/* Only show if the user is the project creator */}
            {projectCreator === currentUserId && (
                <>
                    {console.log("User is the project creator, showing additional features")} {/* Debug */}
                    {/* Translator Assignment Section */}
                    <Box sx={{ marginBottom: '20px' }}>
                        <FormControl fullWidth>
                            <InputLabel>Select Translator</InputLabel>
                            <Select
                                onChange={handleTranslatorChange}
                                fullWidth
                            >
                                <MenuItem value="unassign">Unassign Translator</MenuItem>
                                {teamMembers.map((member) => (
                                    <MenuItem key={member.userId._id} value={member.userId._id}>
                                        {member.userId.email}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>

                    {/* Translation Service Selector */}
                    <Box sx={{ marginBottom: '20px' }}>
                        <FormControl fullWidth>
                            <InputLabel>Select Translation Service</InputLabel>
                            <Select
                                value={translationService}
                                onChange={(e) => setTranslationService(e.target.value)}
                                fullWidth
                            >
                                <MenuItem value="deepl">DeepL</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>

                    {/* Auto-Translate Button */}
                    <Box sx={{ textAlign: 'right', marginBottom: '10px' }}>
                        <Button variant="contained" color="primary" onClick={handleAutoTranslate}>
                            Auto Translate
                        </Button>
                    </Box>
                </>
            )}

            {/* Word and Character Counts */}
            <Box sx={{ marginBottom: '20px' }}>
                <Typography variant="h6">Total Words: {wordCount} | Total Characters: {charCount}</Typography>
            </Box>

            {/* Progress Bars */}
            <Box sx={{ display: 'flex', gap: 2, marginBottom: '20px' }}>
                <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="h6">Overall Progress: {overallProgress.toFixed(2)}%</Typography>
                    <LinearProgress variant="determinate" value={overallProgress} />
                </Box>
                <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="h6">Page Progress: {pageProgress.toFixed(2)}%</Typography>
                    <LinearProgress variant="determinate" value={pageProgress} />
                </Box>
            </Box>

            {/* Sections Grid */}
            <Grid container spacing={4}>
                {currentSections.map((section, index) => (
                    <Grid item xs={12} key={index}>
                        <Grid container spacing={2} alignItems="flex-start">
                            <Grid item xs={5}>
                                <TextField
                                    label="Original Text"
                                    multiline
                                    fullWidth
                                    value={section}
                                    InputProps={{ readOnly: true }}
                                    variant="outlined"
                                    sx={{ height: '100%' }}
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <TextField
                                    label="Translated Text"
                                    multiline
                                    fullWidth
                                    value={translatedSections[index + indexOfFirstSection] || ''}
                                    onChange={(e) => handleTranslationChange(index, e.target.value)}
                                    variant="outlined"
                                    sx={{ height: '100%', backgroundColor: approvedSections.includes(index + indexOfFirstSection) ? '#d4edda' : '' }}
                                    InputProps={{ readOnly: approvedSections.includes(index + indexOfFirstSection) }}
                                />
                            </Grid>
                            <Grid item xs={2} sx={{ textAlign: 'right' }}>
                                <IconButton
                                    color={approvedSections.includes(index + indexOfFirstSection) ? 'secondary' : 'primary'}
                                    onClick={() => toggleApproval(index)}
                                >
                                    {approvedSections.includes(index + indexOfFirstSection) ? <UndoIcon /> : <CheckCircleIcon />}
                                </IconButton>
                                {projectCreator === currentUserId && (
                                    <Button variant="outlined" onClick={() => handleTranslate(index)} sx={{ marginTop: '10px' }}>
                                        Translate
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                ))}
            </Grid>

            {/* Pagination Component */}
            <Box sx={{ textAlign: 'center', marginTop: '40px' }}>
                <Pagination count={Math.ceil(filteredSections.length / sectionsPerPage)} page={currentPage} onChange={handlePageChange} />
            </Box>

            {/* Only show Save button if user is the project creator */}
            
                <Box sx={{ textAlign: 'center', marginTop: '40px' }}>
                    <Button variant="contained" color="primary" onClick={handleSaveTranslation}>
                        Save Translations
                    </Button>
                </Box>
            
        </Container>
    );
};

export default TranslateProject;
