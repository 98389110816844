import React, { useState, useEffect } from 'react';
import {
    Container,
    TextField,
    Button,
    Typography,
    Avatar,
    Box,
    Paper,
    Grid,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@mui/material';
import Flag from 'react-world-flags'; // For displaying flags
import { useSelector } from 'react-redux';
import axios from 'axios';
import { PhotoCamera } from '@mui/icons-material';
import isoLanguages from './isoLanguages'; // Import the ISO languages data

const ProfilePage = () => {
    const token = useSelector((state) => state.auth.token);
    const [profileData, setProfileData] = useState({
        email: '',
        company: '',
        firstName: '',
        lastName: '',
        avatarUrl: '',
        occupation: '',
        linkedIn: '',
        website: '',
        translatedLanguages: '', // Use translatedLanguages as the field
        availability: '',
    });
    const [avatarPreview, setAvatarPreview] = useState('');
    const [avatarFile, setAvatarFile] = useState(null);
    const [message, setMessage] = useState('');

    // Fetch user profile data
    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const response = await axios.get('http://192.168.68.130:5002/users/me', {
                    headers: { Authorization: `Bearer ${token}` },
                });

                const profile = response.data;

                // Normalize translatedLanguages to lowercase if it exists and is a string, or default to an empty string
                setProfileData({
                    ...profile,
                    translatedLanguages: Array.isArray(profile.translatedLanguages) ? profile.translatedLanguages[0]?.toLowerCase() || '' : profile.translatedLanguages?.toLowerCase() || '', // Handle array or string
                });

                if (response.data.avatarUrl) {
                    setAvatarPreview(`http://192.168.68.130:5002${response.data.avatarUrl}`);
                }
            } catch (error) {
                console.error('Error fetching profile data:', error);
            }
        };

        fetchProfileData();
    }, [token]);

    // Find the selected language from the isoLanguages list
    const selectedLanguage = isoLanguages.find(lang => lang.code.toLowerCase() === profileData.translatedLanguages);

    // Handle changes in form inputs
    const handleChange = (e) => {
        setProfileData({ ...profileData, [e.target.name]: e.target.value });
    };

    // Handle avatar upload
    const handleAvatarUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setAvatarPreview(reader.result);
            };
            reader.readAsDataURL(file);
            setAvatarFile(file);
        }
    };

    // Save profile data
    const handleSave = async () => {
        const formData = new FormData();
        formData.append('firstName', profileData.firstName);
        formData.append('lastName', profileData.lastName);
        formData.append('company', profileData.company);
        formData.append('occupation', profileData.occupation);
        formData.append('linkedIn', profileData.linkedIn);
        formData.append('website', profileData.website);
        formData.append('translatedLanguages', profileData.translatedLanguages); // Send the language as ISO string

        // Append the avatar file if it exists
        if (avatarFile) {
            formData.append('avatar', avatarFile);
        }

        try {
            await axios.put('http://192.168.68.130:5002/users/me', formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            });
            setMessage('Profile updated successfully');
        } catch (error) {
            console.error('Error updating profile:', error);
            setMessage('Error updating profile');
        }
    };

    return (
        <Container maxWidth="md" sx={{ marginTop: '50px' }}>
            <Paper elevation={3} sx={{ padding: '30px', borderRadius: '10px' }}>
                <Typography variant="h4" gutterBottom>Profile Information</Typography>

                {/* Avatar Upload */}
                <Box sx={{ textAlign: 'center', marginBottom: '20px' }}>
                    <Avatar src={avatarPreview} sx={{ width: 100, height: 100, margin: 'auto' }} />
                    <input
                        accept="image/*"
                        style={{ display: 'none' }}
                        id="avatar-upload"
                        type="file"
                        onChange={handleAvatarUpload}
                    />
                    <label htmlFor="avatar-upload">
                        <IconButton color="primary" component="span">
                            <PhotoCamera />
                        </IconButton>
                    </label>
                </Box>

                {/* Profile Form */}
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            label="Email"
                            variant="outlined"
                            fullWidth
                            value={profileData.email}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="First Name"
                            variant="outlined"
                            fullWidth
                            name="firstName"
                            value={profileData.firstName}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Last Name"
                            variant="outlined"
                            fullWidth
                            name="lastName"
                            value={profileData.lastName}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Company"
                            variant="outlined"
                            fullWidth
                            name="company"
                            value={profileData.company}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Occupation"
                            variant="outlined"
                            fullWidth
                            name="occupation"
                            value={profileData.occupation}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="LinkedIn Profile"
                            variant="outlined"
                            fullWidth
                            name="linkedIn"
                            value={profileData.linkedIn}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Website/Portfolio URL"
                            variant="outlined"
                            fullWidth
                            name="website"
                            value={profileData.website}
                            onChange={handleChange}
                        />
                    </Grid>

                    {/* Language Selection */}
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel>Translated Language</InputLabel>
                            <Select
                                value={profileData.translatedLanguages || ''}  // Store selected language ISO code
                                onChange={(e) => {
                                    setProfileData({ ...profileData, translatedLanguages: e.target.value.toLowerCase() });  // Ensure it's saved in lowercase
                                }}
                                fullWidth
                            >
                                {isoLanguages.map((lang) => (
                                    <MenuItem key={lang.code} value={lang.code.toLowerCase()}>  {/* Display in uppercase for visual */}
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Flag code={lang.countryCode} style={{ marginRight: '10px', width: '20px' }} />
                                            {lang.name} ({lang.code.toUpperCase()})
                                        </Box>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    {/* Selected Language Display */}
                    {selectedLanguage && (
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Flag code={selectedLanguage.countryCode} style={{ marginRight: '10px', width: '30px' }} />
                                <Typography>{selectedLanguage.name}</Typography>
                            </Box>
                        </Grid>
                    )}

                    <Grid item xs={6}>
                        <TextField
                            label="Availability"
                            variant="outlined"
                            fullWidth
                            name="availability"
                            value={profileData.availability}
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>

                {/* Save Button */}
                <Box sx={{ marginTop: '20px' }}>
                    <Button variant="contained" color="primary" onClick={handleSave}>
                        Save Changes
                    </Button>
                </Box>

                {message && (
                    <Typography sx={{ marginTop: '20px' }} color="success.main">
                        {message}
                    </Typography>
                )}
            </Paper>
        </Container>
    );
};

export default ProfilePage;
