import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, Grid, Paper, Button, LinearProgress } from '@mui/material';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const DashboardHome = () => {
    const [activeProjects, setActiveProjects] = useState(0);
    const [completedProjects, setCompletedProjects] = useState(0);
    const [recentProjects, setRecentProjects] = useState([]);
    const [wordsTranslated, setWordsTranslated] = useState(0);
    const [topLanguages, setTopLanguages] = useState([]);
    const [loading, setLoading] = useState(true);  // Add loading state
    const [error, setError] = useState(null); // Add error state
    const [apiUsage, setApiUsage] = useState(null); // Initially set to null for API usage
    const navigate = useNavigate(); // Hook for navigation

    const token = useSelector((state) => state.auth.token);

    // Function to handle unauthorized access (token expiration)
    const handleUnauthorized = () => {
        localStorage.removeItem('token'); // Remove token from local storage
        navigate('/login'); // Redirect to login page
    };

    // Fetch data for dashboard overview
    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch project overview
                const projectsResponse = await axios.get('http://192.168.68.130:5002/projects', {
                    headers: { Authorization: `Bearer ${token}` },
                });

                const projects = projectsResponse.data;
                const active = projects.filter(project => !project.completed).length;
                const completed = projects.filter(project => project.completed).length;
                setActiveProjects(active);
                setCompletedProjects(completed);
                setRecentProjects(projects.slice(0, 5)); // Get last 5 recent projects

                // Fetch words translated, top languages, and completed projects
                const statsResponse = await axios.get('http://192.168.68.130:5002/stats', {
                    headers: { Authorization: `Bearer ${token}` },
                });
                const { wordsTranslated, topLanguages, completedProjects } = statsResponse.data;

                setWordsTranslated(wordsTranslated);
                setTopLanguages(topLanguages);
                setCompletedProjects(completedProjects); // Update the completed projects

                // Fetch DeepL API usage
                try {
                    const apiUsageResponse = await axios.get('http://192.168.68.130:5002/deepl/usage', {
                        headers: { Authorization: `Bearer ${token}` },
                    });

                    setApiUsage(apiUsageResponse.data);
                } catch (deeplError) {
                    console.log('DeepL API key is not available or API usage request failed:', deeplError);
                    setApiUsage(null);  // If the DeepL API request fails, handle it gracefully
                }

                // Set loading to false after all data is fetched
                setLoading(false);
            } catch (err) {
                if (err.response && err.response.status === 401) {
                    // Token expired or unauthorized, handle it
                    handleUnauthorized();
                } else {
                    console.error('Error fetching dashboard data:', err);
                    setError('Failed to load dashboard data.');
                    setLoading(false);  // Set loading to false if there's an error
                }
            }
        };

        fetchData();
    }, [token, navigate]);

    if (loading) {
        return <Typography variant="h5">Loading...</Typography>;
    }

    if (error) {
        return <Typography variant="h5" color="error">{error}</Typography>;
    }

    return (
        <Container maxWidth="lg" sx={{ marginTop: '50px' }}>
            {/* Image at the top */}
            <Box sx={{ textAlign: 'left', marginBottom: '20px' }}>
                <img
                    src="images/workspace-default.png"
                    alt="Workspace"
                    style={{ maxWidth: '100%', height: 'auto', borderRadius: '10px' }}
                />
            </Box>

            {/* Header and paragraph */}
            <Typography variant="h4" gutterBottom textAlign="left">
                Your Translation Workspace
            </Typography>
            <Typography variant="body1" textAlign="left" gutterBottom marginBottom="15px">
                This is your hub for managing all translation projects. Track your progress, explore language insights, and manage your active and completed tasks. Stay organized and boost productivity by using the tools and resources available in your translation workspace.
            </Typography>

            {/* Project Overview */}
            <Grid container spacing={4}>
                <Grid item xs={12} md={4}>
                    <Paper elevation={3} sx={{ padding: '20px', textAlign: 'center' }}>
                        <Typography variant="h6">Active Projects</Typography>
                        <Typography variant="h4">{activeProjects}</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Paper elevation={3} sx={{ padding: '20px', textAlign: 'center' }}>
                        <Typography variant="h6">Completed Projects</Typography>
                        <Typography variant="h4">{completedProjects}</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Paper elevation={3} sx={{ padding: '20px', textAlign: 'center' }}>
                        <Typography variant="h6">Words Translated This Month</Typography>
                        <Typography variant="h4">{wordsTranslated}</Typography>
                    </Paper>
                </Grid>
            </Grid>

            {/* Recent Projects */}
            <Box sx={{ marginTop: '40px' }}>
                <Typography variant="h5" gutterBottom>
                    Recent Projects
                </Typography>
                {recentProjects.length > 0 ? (
                    <Grid container spacing={4}>
                        {recentProjects.map((project) => (
                            <Grid item xs={12} md={4} key={project._id}>
                                <Paper elevation={3} sx={{ padding: '20px' }}>
                                    <Typography variant="h6">{project.projectName}</Typography>
                                    <Typography variant="body2">Source: {project.sourceLanguage}</Typography>
                                    <Typography variant="body2">Target: {project.targetLanguage}</Typography>
                                    <Typography variant="body2">Progress: {project.progress}%</Typography>
                                    <Box sx={{ marginTop: '10px' }}>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            onClick={() => window.location.href = `/dashboard/projects/${project._id}/translate`}
                                        >
                                            Continue Translation
                                        </Button>
                                    </Box>
                                </Paper>
                            </Grid>
                        ))}
                    </Grid>
                ) : (
                    <Typography>No recent projects found.</Typography>
                )}
            </Box>

            {/* Top Languages Used */}
            <Box sx={{ marginTop: '40px' }}>
                <Typography variant="h5" gutterBottom>
                    Top Languages Used
                </Typography>
                <Grid container spacing={4}>
                    {topLanguages.map((language, index) => (
                        <Grid item xs={12} md={4} key={index}>
                            <Paper elevation={3} sx={{ padding: '20px', textAlign: 'center' }}>
                                <Typography variant="h6">{language.name}</Typography>
                                <Typography variant="body2">Used {language.count} times</Typography>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </Box>

            {/* API Usage with Progress Bar */}
            <Box sx={{ marginTop: '40px' }}>
                <Typography variant="h5" gutterBottom>
                    DeepL API Usage
                </Typography>
                <Paper elevation={3} sx={{ padding: '20px' }}>
                    {apiUsage && apiUsage.character_count !== undefined && apiUsage.character_limit !== undefined ? (
                        <>
                            <Typography variant="body1">
                                {apiUsage.character_count} characters translated out of {apiUsage.character_limit} available.
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                                <LinearProgress
                                    variant="determinate"
                                    value={(apiUsage.character_count / apiUsage.character_limit) * 100}
                                    sx={{ flexGrow: 1, marginRight: '10px' }}
                                />
                                <Typography variant="body2">
                                    {(apiUsage.character_count / apiUsage.character_limit * 100).toFixed(2)}%
                                </Typography>
                            </Box>
                        </>
                    ) : (
                        <Typography variant="body1">API usage data unavailable.</Typography>
                    )}
                </Paper>
            </Box>
        </Container>
    );
};

export default DashboardHome;
